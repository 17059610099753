exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leasing-tsx": () => import("./../../../src/pages/leasing.tsx" /* webpackChunkName: "component---src-pages-leasing-tsx" */),
  "component---src-pages-photo-tsx": () => import("./../../../src/pages/photo.tsx" /* webpackChunkName: "component---src-pages-photo-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-repair-tsx": () => import("./../../../src/pages/repair.tsx" /* webpackChunkName: "component---src-pages-repair-tsx" */),
  "component---src-templates-catalog-tsx": () => import("./../../../src/templates/catalog.tsx" /* webpackChunkName: "component---src-templates-catalog-tsx" */),
  "component---src-templates-item-tsx": () => import("./../../../src/templates/item.tsx" /* webpackChunkName: "component---src-templates-item-tsx" */)
}

